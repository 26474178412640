import styled from 'styled-components';

export const GraphWrapper = styled.div`
#container{
    display: flex;
    flex-direction: row;
    border: 2px solid blue;
}

.box {
  flex: 1 1 50%;
}

#cy, iframe{
    height:500px;
}

iframe{
  width:100%;
}

@media (max-width: 800px) {
  #container {
    flex-direction: column;
    height: 100%;
  }
  .box {
    flex: 1 1 50%;
  }
}

.hovered-node {
  cursor: pointer;
}


`;

// @media (min-width: 768px) {
//   .box {
//     flex-basis: 50%;
//   }
// }