import React, { FC } from 'react';
import { MenubarWrapper } from './Menubar.styled';
import Icon from '@mui/material/Icon'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import bjjmazeIcon from './bjjmaze_logo.png';

interface MenubarProps { }

type Page = {
   label: string;
   link: string;
   isNewTab?: boolean;
};

const pages: Page[] = [
   { label: 'Home', link: '/' },
   { label: 'Contact', link: '/contact' },
   { label: 'Wiki', link: 'https://wiki.bjjmaze.com/index.php/Main_Page', isNewTab: true }
];

function PageLink({ page, children }: { page: Page, children?: React.ReactNode }) {
   return (
      <Link
         key={page.label}
         to={page.link}
         style={{ textDecoration: 'none' }}
         target={page.isNewTab ? '_blank' : undefined}
         rel={page.isNewTab ? 'noopener noreferrer' : undefined}
      >
         {children}
      </Link>
   );
}

function Menubar(props: MenubarProps) {
   const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
   const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

   const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
   };
   const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
   };

   const handleCloseNavMenu = () => {
      setAnchorElNav(null);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   return <AppBar position="static">
      <Container maxWidth="xl">
         <Toolbar disableGutters>
            <Icon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}>
               <img src={bjjmazeIcon} alt="My Custom Icon" />
            </Icon>
            <Typography
               variant="h6"
               noWrap
               component="a"
               href="/"
               sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
               }}
            >
               BjjMaze
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
               <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
               >
                  <MenuIcon />
               </IconButton>
               <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                     display: { xs: 'block', md: 'none' },
                  }}
               >
                  {pages.map((page) => (
                     <MenuItem key={page.label + 'horizontal'} onClick={handleCloseNavMenu}>
                        <PageLink page={page}>
                           <Typography textAlign="center">{page.label}</Typography>
                        </PageLink>
                     </MenuItem>
                  ))}
               </Menu>
            </Box>
            <Icon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}>
               <img src={bjjmazeIcon} alt="My Custom Icon" />
            </Icon>
            <Typography
               variant="h5"
               noWrap
               component="a"
               href=""
               sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
               }}
            >
               BjjMaze
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
               {pages.map((page) => (
                  <PageLink page={page}>
                     <Button
                        onClick={handleCloseNavMenu}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                     >
                        {page.label}
                     </Button>
                  </PageLink>
               ))}
            </Box>
         </Toolbar>
      </Container>
   </AppBar>
}

export default Menubar;
