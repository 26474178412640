import React, { FC } from 'react';
import { ContactWrapper } from './Contact.styled';

interface ContactProps {}

const Contact: FC<ContactProps> = () => (
 <ContactWrapper>
    Do you have questions or feedback?
    <br/>
    Is there anything you want me to add to this website?
    <br/>
    Contact me at bjjmaze@cookbookcoding.com
 </ContactWrapper>
);

export default Contact;
