import React, { FC } from 'react';
import { GraphWrapper } from './Graph.styled';
import cytoscape from 'cytoscape';
import GraphData from './GraphData';

type GraphProps = {
};
type GraphState = {
   selectedNodeUrl?: string;
   error?: any;
   isLatestGraphWorking: boolean;
};

// The graph data urls will be attempted one at a time until a successful one is found.
const urls = [
   'https://bjjmaze-graph-data.s3.amazonaws.com/graph_extraction/latest.json',
   'https://bjjmaze-graph-data.s3.amazonaws.com/graph_extraction/2023-05-19T00%3A00%3A18.248103.json',
];

class Graph extends React.Component<GraphProps, GraphState> {
   state: GraphState = {
      error: undefined,
      selectedNodeUrl: "https://wiki.bjjmaze.com/index.php/Closed_guard",
      isLatestGraphWorking: true,
   };
   cytoscapeContainerRef = React.createRef<HTMLDivElement>();
   iframeRef = React.createRef<HTMLIFrameElement>();
   cy?: cytoscape.Core;


   constructor(props: GraphProps) {
      super(props);
   }

   componentDidMount = async () => {
      try {
         const graph: GraphData = await this.fetchGraphDataFromUrls(urls);
         this.createCytoscapeGraph(graph);
      } catch (error) {
         console.error(error);
         this.setState({ error });
      }
   }

   fetchGraphDataFromUrls = async (urls: string[]): Promise<GraphData> => {
      for (const url of urls) {
         try {
            const graph = await this.fetchGraphData(url);
            return graph;
         } catch (error) {
            this.setState({ isLatestGraphWorking: false });
            console.error(error);
         }
      }
      throw new Error('Failed to fetch graph data from all URLs');
   }

   fetchGraphData = async (url: string): Promise<GraphData> => {
      const response = await fetch(url);
      if (!response.ok) {
         throw new Error(`Failed to fetch graph data from ${url}`);
      }
      const graph: GraphData = await response.json();
      return graph;
   }

   createCytoscapeGraph = (graph: GraphData) => {
      this.cy = cytoscape({
         container: this.cytoscapeContainerRef.current,
         elements: {
            nodes: graph.nodes.map((node: any) => ({
               data: {
                  id: node.article_href,
                  label: node.label || node.article_title,
                  url: 'https://wiki.bjjmaze.com/' + node.article_href
               },
            })),
            edges: graph.edges.map((edge: any) => ({
               data: {
                  id: `${edge.src_article_href}-${edge.dest_article_href}`,
                  source: edge.src_article_href,
                  target: edge.dest_article_href
               },
            })),
         },
         style: [
            {
               selector: 'node',
               style: {
                  'label': 'data(label)',
                  'font-size': '20x',
                  'height': 80,
                  'width': 80,
                  'text-max-width': '80px',
                  'background-color': '#ADD8E6',
                  'text-wrap': 'wrap',
                  'line-height': 1.3,
                  'text-halign': 'center',
                  'text-valign': 'center',
               }
            },
            {
               selector: 'edge',
               style: {
                  'width': 3,
                  'line-color': '#ccc',
                  'target-arrow-color': '#ccc',
                  'target-arrow-shape': 'triangle',
                  'curve-style': 'bezier',
                  'arrow-scale': 3,
               }
            }
         ]
      });

      this.cy.on('tap', 'node', (event: any) => {
         const node = event.target;
         this.setState({ selectedNodeUrl: node.data('url') });

      });

      this.cy.on('mouseover', (event) => {
         if (event.cy.container()) {
            event.cy.container()!.style.cursor = 'pointer';
         }
      });

      this.cy.on('mouseout', (event) => {
         if (event.cy.container()) {
            event.cy.container()!.style.cursor = 'default';
         }
      });

      this.resetCytoscape();
   }

   recenterCytoscape = () => this.cy?.fit(undefined, 30);

   resetCytoscape = () =>
      this.cy?.layout({
         name: 'breadthfirst',
         animate: true,
         fit: true,
         padding: 30
      }).run();

   render = () => {
      const { error, selectedNodeUrl } = this.state;
      if (error) {
         console.log('graph error');
         return <p>Error: {error.message}</p>;
      }

      return (
         <GraphWrapper>
            <div>Click the circles on the graph, the wiki article will appear on the right (or bottom).</div>
            <div>To edit this graph, edit the <a href='https://wiki.bjjmaze.com/index.php/BjjMaze:Graph'>BjjMaze:Graph</a> wiki article.</div>
            <div id="container">
               <div className="box">
                  <button onClick={this.recenterCytoscape}>Recenter graph</button>
                  <button onClick={this.resetCytoscape}>Reset graph</button>
                  <div id="cy" ref={this.cytoscapeContainerRef}></div>
               </div>
               <div className="box">
                  <div>
                     <div>To edit this article, go to its wiki <a target="_blank" href={selectedNodeUrl}>page</a>.</div>
                     <iframe id="wiki" src={selectedNodeUrl} ref={this.iframeRef}></iframe>
                  </div>
               </div>
            </div>
            {!this.state.isLatestGraphWorking &&
               (<div>The latest graph data was not loaded correctly.
                  It could be because the <a href='https://wiki.bjjmaze.com/index.php/BjjMaze:Graph'>BjjMaze:Graph</a> article was not formatted correctly.
                  <br />
                  We've loaded some back up graph data, so it may not have recent changes.
               </div>)}
         </GraphWrapper>
      );
   }
}

export default Graph;
