import './App.css';
import Contact from './components/Contact/Contact';
import Graph from './components/Graph/Graph';

import Menubar from './components/Menubar/Menubar';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {

  return (
    <Router>
      <Menubar></Menubar>
      <Routes>
        <Route path="/" element={<Graph/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
    </Router >
  );
}

export default App;
